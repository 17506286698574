import gsap from 'gsap'
import lottie from 'lottie-web'
import ScrollTrigger from "gsap/ScrollTrigger";
export function initScrollytelling() {
    return [...document.querySelectorAll('.hero-container--svg-wrapper')].map(e => new Scrollytelling(e))

}
export class Scrollytelling {
    constructor(wrapper) {
        let path = LottiFilePath ? LottiFilePath : "/wp-content/themes/yesdevs-theme/assets/svg/test.json";
        let speed = (LottiSpeed !== null && LottiSpeed !== "") ? LottiSpeed : "600";
        let overlay = document.querySelector('.lottie-trigger--overlay');
        let target = gsap.utils.toArray(".lottie-trigger")[0];
        const logo = document.querySelector('.lottie-trigger--logo-wrapper');

        let animation = lottie.loadAnimation({
            container: target,
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: path
        });
        animation.addEventListener("DOMLoaded", function() {
            gsap.registerPlugin(ScrollTrigger);
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: target,
                    pin: true,
                    scrub: true,
                    start: "10px top",
                    // end: "+=700%",
                    end: "+=" + speed +"%",
                    onUpdate: self => {
                        const progress = self.progress * (animation.totalFrames - 1);
                        animation.goToAndStop(progress, true);

                        if (progress === 0) {
                            overlay.style.opacity = "0";
                            if(logo){
                                logo.style.opacity = "1";
                            }
                        } else {
                            overlay.style.opacity = "1";
                            if(logo){
                                logo.style.opacity = "0";
                            }
                        }


                        if(target.classList.contains('websitewall')){
                            if (progress > 600) {
                                if(logo){
                                    logo.style.opacity = "1";
                                }
                            }
                        }
                        else{
                            if(logo){
                                logo.style.opacity = "0";
                            }
                            if (progress <= 100) {
                                logo.style.opacity = "1";
                            }
                        }
                    },
                    onLeave: () => {
                        overlay.style.opacity = "0";
                        if(logo){
                            logo.style.opacity = "1";
                        }
                    },
                    onEnterBack: () => {
                        overlay.style.opacity = "1";
                        if(logo){
                            logo.style.opacity = "1";
                        }
                    }
                }
            });
            ScrollTrigger.config({
                autoRefreshEvents: "visibilitychange,DOMContentLoaded,load" // notice "resize" isn't in the list
            });

            const container = document.querySelector('.websitewall');


            // Create an Image object to preload the first image
            if(container){
                const pathElems = container.querySelectorAll('path');

                const firstImg = new Image();
                const firstImgSrc = images[0];
                firstImg.src = firstImgSrc;

                // Loop through each path element
                pathElems.forEach((pathElem, index) => {

                    // Create the image element and set its attributes
                    const imgElem = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                    imgElem.setAttribute('href', firstImgSrc);
                    // Get the bounding box of the path element
                    const bbox = pathElem.getBBox();
                    imgElem.setAttribute('x', bbox.x);
                    imgElem.setAttribute('y', bbox.y);
                    imgElem.setAttribute('width', bbox.width);
                    imgElem.setAttribute('height', bbox.height);
                    imgElem.setAttribute('preserveAspectRatio', 'xMidYMid slice');

                    // Append the image element to the parent of the current path element
                    pathElem.parentNode.append(imgElem, pathElem);

                    // Check if there are additional images to preload and add
                    if (index > 0 && images[index]) {
                        // Create an Image object to preload the image
                        const img = new Image();
                        const image = images[index];
                        img.src = image;

                        img.onload = () => {
                            // Update the href attribute of the image element to the new image
                            imgElem.setAttribute('href', image);
                        };
                    }
                });

            }
        });
    }
}