class IconListUpdated {
    constructor() {
        this.init();
    }

    init() {
        [...document.querySelectorAll('.icon_grid.updated')]
            .map(e => this.loadMore(e));
    }

    loadMore(container) {
        let btn = container.querySelector('.icon_grid.updated a.button-action[data-action="load-more"]');
        // let value = document.querySelector('.icon_grid.updated .icon_grid__icons').dataset.value;
        let items = container.querySelectorAll('.icon_grid.updated .icon_grid__icons .icon_grid__icons__item');

        if (container.classList.contains('-is-animated')) {
            this.animateIcons(container);
        }

        if (btn) {
            btn.addEventListener('click', () => {
                let count = 0;
                for (let i = 0; i < items.length; i++) {
                    if (items[i].classList.contains('-is-hidden')) {
                        items[i].classList.remove('-is-hidden');
                        count++;
                    }
                }
                btn.remove();
            });
        }
    }

    // Replace item container contents with a scale in/out animation
    animateIcons(container) {

        // Function to swap contents of two items
        function swapContents(item1, item2) {

            // Get the contents of each item
            const content1 = item1.innerHTML;
            const content2 = item2.innerHTML;

            // Apply scale out animation to both items
            item1.classList.add('icon_grid__icons__item-scale');
            item2.classList.add('icon_grid__icons__item-scale');

            // Wait for the scale out animation to complete
            setTimeout(() => {
                // Swap the contents
                item1.innerHTML = content2;
                item2.innerHTML = content1;

                // Remove scaling class to scale back in
                setTimeout(() => {
                    item1.classList.remove('icon_grid__icons__item-scale');
                    item2.classList.remove('icon_grid__icons__item-scale');
                }, 50);
            }, 300); // Match this duration to the CSS transition duration
        }

        // Set interval to perform swaps
        setInterval(() => {
            // Refresh the items and hiddenItems in each interval
            let items = container.querySelectorAll('.icon_grid.updated .icon_grid__icons__item:not(.-is-hidden):not(.-avoid-swap)');
            let hiddenItems = container.querySelectorAll('.icon_grid.updated .icon_grid__icons__item.-is-hidden');

            // Check if there are any items to swap
            if (items.length === 0 || hiddenItems.length === 0) {
                return; // Stop swapping if no visible or hidden items exist
            }

            // Select a random item that is not hidden and a random hidden item
            let randomIndex = Math.floor(Math.random() * items.length);
            let randomHiddenIndex = Math.floor(Math.random() * hiddenItems.length);

            swapContents(items[randomIndex], hiddenItems[randomHiddenIndex]);
        }, 3000);

        // TODO: modify to avoid replacement between same block but different item groups for customer, cooperation and partner

    }
}

export default IconListUpdated;

