import Flickity from "flickity";
import barba from "@barba/core";
import {initScrollytelling} from "./scrollytelling";


class Slider {
  constructor() {
    this.init();
    initScrollytelling();
  }

  init() {
    [...document.querySelectorAll('.cases')].map(e => {
      this.slider(e);
      this.columns(e);
    });
    [...document.querySelectorAll('.cases.with-animation')].map(e => this.animate(e));

  }

  animate(block) {
    window.wp.utils.isElementInViewport(block, 
      () => {
        if(!block.classList.contains("ready")) {
          block.classList.add("animate");
          const children = block.querySelectorAll(".cases__columns__item"); 
          let time = 0;
          children.forEach((child) => {
            setTimeout(() => child.classList.add("animate"), time)
            time += 700;
          });
        }
      },
      () => {
        if(block.classList.contains("animate")) {
          const children = block.querySelectorAll(".cases__carousel > div"); 
          children.forEach((child) => {
            child.classList.remove("animate");
            child.classList.add("ready");
          });
          block.classList.remove("animate");
          block.classList.add("ready");
        }
      },
      "-100px"
    );
  }

  slider(block) {
    const slider = block.querySelector(".cases__carousel");
    if (slider) {
      const flkty = new Flickity(slider, {
        initialIndex: 0,
        groupCells: false,
        pageDots: false,
        wrapAround: false,
        draggable: true,
        cellAlign: "right",
        prevNextButtons: false,
      });
      flkty.on(
        "staticClick",
        function (event, pointer, cellElement, cellIndex) {
          const href = cellElement.getAttribute("href");
          if (href) {
            barba.history.add(window.location.href, cellElement);
            barba.go(href, cellElement).then(() => {
            });

          }
        }
      );
    }
  }

  columns(block) {
    const columns = block.querySelector(".cases__columns");
    if (columns) {
      const items = Array.from(columns.querySelectorAll(".cases__columns__item"));
      items.forEach((item, index) => {
        // if (index >= 4) {
        //   item.classList.add('hide');
        // }

        item.addEventListener("click", () => {
          const href = item.getAttribute("href");
          if (href) {
            barba.history.add(window.location.href, item);
            barba.go(href, item).then(() => { });
          }
        });
      });

      // Create 'Load More' button only if there are more than 4 items
      // if (items.length > 4) {
      //   // Determine the page's language and set the button's text accordingly
      //   const lang = document.documentElement.lang;
      //   let buttonText = 'Mehr laden'; // default
      //   if (lang === 'en-US') {
      //     buttonText = 'Load More';
      //   } else if (lang === 'es-ES') {
      //     buttonText = 'Más información';
      //   }
      //
      //   const loadMoreButton = document.createElement('button');
      //   loadMoreButton.textContent = buttonText;
      //   loadMoreButton.classList.add('-load-more');
      //   loadMoreButton.addEventListener('click', () => {
      //     let hiddenItems = Array.from(document.querySelectorAll('.cases__columns__item.hide'));
      //     hiddenItems.slice(0, 4).forEach(item => item.classList.remove('hide'));
      //
      //     // If no more hidden items, hide the 'Load More' button
      //     if (hiddenItems.length <= 4) {
      //       loadMoreButton.style.display = 'none';
      //     }
      //   });
      //
      //   columns.appendChild(loadMoreButton);
      // }
    }
  }
}

export default Slider;
