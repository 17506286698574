class Employee {
  constructor() {
    this.init();
  }
  init() {
    const container = document.querySelector(".employee_card:not(.ready)");
    window.wp.utils.isElementInViewport(
      container,
      function () {
        if (container) {
          container.classList.add("ready");
        }
      },
      () => {},
      "0px"
    );
  }
}

export default Employee;
