import axios from "axios"

class News {

    constructor() {
        this.container = undefined;
        this.loadMore = undefined;
        this.loader = undefined;
        this.limit = undefined;
        this.currentPage = 1;

        if (globalConfig !== undefined && globalConfig?.nonce) {
            axios.defaults.headers.common["X-WP-Nonce"] = globalConfig.nonce;
        }

        this.init();
    }

    init() {
        this.loadMore = document.querySelector('#load_more_post');

        if (this.loadMore) {

            this.container = this.loadMore.parentElement.querySelector('.news-list-grid');
            this.loader = this.loadMore.parentElement.querySelector('.news-list-loader');

            this.limit = this.loadMore.dataset.limit;

            this.attachEvents();
        }
    }

    attachEvents() {
        this.loadMorePost();

        this.loadMore.addEventListener("click", () => {
            this.loadMorePost();
        });
    }

    formatData() {
        let data = new FormData;
        data.append('action', 'loadmore');
        data.append('limit', this.limit);
        data.append('page', this.currentPage);

        return data;
    }

    toggleSearchElem() {
        let button = this.loadMore;
        let loader = this.loader;

        button.disabled = !button.disabled;

        if (loader.classList.contains('show'))
            loader.classList.remove('show');
        else
            loader.classList.add('show');
    }

    loadMorePost() {
        let button = this.loadMore;
        let container = this.container;
        let formData = this.formatData();

        this.toggleSearchElem();

        axios.post(globalConfig.url, formData)
            .then(({data}) => {
                this.currentPage++;
                this.toggleSearchElem();

                if (data === "" || data === undefined || data === null) {
                    let parentElement = button.parentElement;
                    parentElement.removeChild(button);
                } else {
                    container.insertAdjacentHTML('beforeend', data);
                    this.activeNewItems();
                }
            })
    }

    activeNewItems() {
        let items = this.container.querySelectorAll(".news.inactive");
        if (items && items?.length) {
            for (let i = 0; i < items?.length ; i++) {
                setTimeout(() => {
                    items[i].classList.remove("inactive");
                }, (800 * i));
            }
        }
    }

}

export default News;