class Facts {
  constructor() {
    this.init();
  }

  animateCountValue(elem, start, end, duration) {
    if (start === end) return;
    let current = start;
    const steps = Math.abs(end - start);
    if (duration < steps) {
      const stepTime = 33;
      let index = 0;
      const increment_size = Math.round(steps / duration * stepTime );
      const max_index = Math.floor(steps / increment_size );
      const increment = end > start ? increment_size : -increment_size;
      const timer = setInterval(function () {
        index++;
        current += increment;
        elem.innerHTML = current.toLocaleString();
        if (index >= max_index) {
          elem.innerHTML = Number.parseInt(end).toLocaleString();
          clearInterval(timer);
        }
      }, stepTime);
    } else {
      const increment = end > start ? 1 : -1;
      const stepTime = Math.abs(Math.floor(duration / steps));
      const timer = setInterval(function () {
        current += increment;
        elem.innerHTML = current.toLocaleString();
        if (current == end) {
          elem.innerHTML = Number.parseInt(end).toLocaleString();
          clearInterval(timer);
        }
      }, stepTime);
    }
  }

  init() {
    const self = this;
    const container = document.querySelector(".facts:not(.ready)");
    window.wp.utils.isElementInViewport(
      container,
      function () {
        if (container) {
          let numbers = document.querySelectorAll(
            ".facts__item__number__value"
          );
          if (numbers.length) {
            let duration =
                container.dataset.duration && container.dataset.duration !== ""
                    ? container.dataset.duration
                    : 5000;
            numbers.forEach((item) => {
              self.animateCountValue(item, 0, item.dataset.max, duration);
            });
          }

          container.classList.add("ready");
        }
      },
      () => {},
      "0px"
    );
  }
}

export default Facts;
