import { shuffleArray } from "../../../assets/js/utils";

class IconList {
  constructor() {
    this.init();
  }

  init() {
    // [...document.querySelectorAll('.icon_grid.with-animation')].map(e => this.animate(e));
    [...document.querySelectorAll('.icon_grid')].map(e => this.animate(e));

  }

  animate(block) {
    window.wp.utils.isElementInViewport(block, 
      () => {
        if(!block.classList.contains("ready")) {
          block.classList.add("animate");
          const children = shuffleArray([...block.querySelectorAll(".icon_grid__icons__item")]); 
          let time = 0;
          const chunkSize = 3;
          for (let i = 0; i < children.length; i += chunkSize) {
            const chunk = [...children].slice(i, i + chunkSize);
            chunk.forEach((child) => setTimeout(() => child.classList.add("animate"), time));
            if(time < 1000) time += 100;
          }
        }
      },
      () => {
        if(block.classList.contains("animate")) {
          const children = block.querySelectorAll(".icon_grid__icons__item"); 
          children.forEach((child) => {
            child.classList.remove("animate");
            child.classList.add("ready");
          });
          block.classList.remove("animate");
          block.classList.add("ready");
        }
      },
      "-100px"
    );
  }
}

export default IconList;
