import barba from "@barba/core";

export default class BarbaClass {
    constructor() {
        this.init();
    }

    init() {
        let body = document.body;

        barba.hooks.before((data) => {
            if (body.classList.contains('error404')) {
                body.classList.remove('error404');
            }
        });
    }
}