import Flickity from "flickity";

class Slider {
  constructor() {
    this.flkty = undefined;
    this.slider = undefined;
    this.init();
  }

  init() {
    this.slider = document.querySelector(".slider-carousel");
    if (this.slider) {
      this.flkty = new Flickity(this.slider, {
        groupCells: true,
        pageDots: false,
        draggable: true,
        cellAlign: 'left',
        arrowShape: "",
      });
    }
  }
}

export default Slider;
