class HeadlineText {
  constructor() {
    this.init();
  }
  
  init() {
    [...document.querySelectorAll('.headline-text.with-animation')].map(e => this.animate(e));
  }

  animate(block) {
    window.wp.utils.isElementInViewport(block, 
      () => {
        if(!block.classList.contains("ready")) block.classList.add("animate");
      },
      () => {
        if(block.classList.contains("animate")) {
          block.classList.remove("animate");
          block.classList.add("ready");
        }
      },
      "-100px"
    );
  }
}

export default HeadlineText;