import axios from "axios";

class Filter {

    constructor() {
        this.container = undefined;
        this.grid = undefined;
        this.loader = undefined;
        this.options = undefined;
        this.filterValue = undefined;

        this.init();
    }

    init() {
        this.container = document.querySelector(".jobs-filter");
        if (this.container) {
            this.grid = this.container.querySelector(".jobs-filter__grid");
            this.options = this.container.querySelectorAll(".jobs-filter__options button.jobs-filter__options__item");

            if (this.options) {
                this.attachEvents();
                let active = [...this.options].filter((item) => item.classList.contains("active"));
                this.setFilterValue(active[0]);
            }
        }
    }

    attachEvents() {
        for (const options of this.options) {
            options.addEventListener("click", (event) => {
                this.setFilterValue(event.target);
            });
        }
    }

    setFilterValue(el) {
        let val = el.dataset.value;
        if (this.filterValue !== val) {
            this.filterValue = val;
            this.renderFilter(el);
            this.renderItems();
        }
    }

    renderFilter(el) {
        let active = [...this.options].filter((item) => item.classList.contains("active"));
        active[0].classList.remove("active")
        el.classList.add("active");
    }

    renderItems() {
        let items = this.grid.querySelectorAll(".jobs-filter__grid__item");
        if (items && items?.length) {
            for (let i = 0; i < items?.length; i++) {
                // setTimeout(() => {
                this.toggleItems(items[i]);
                // }, (800 * i));
            }
        }
    }

    toggleItems(item) {
        let className = this.filterValue.substring(1);
        if (item.classList.contains(className)) {
            this.activeItems(item);
        } else {
            this.inactiveItems(item);
        }
    }

    activeItems(item) {
        if (item.classList.contains("inactive"))
            item.classList.remove("inactive");
    }

    inactiveItems(item) {
        if (!item.classList.contains("inactive"))
            item.classList.add("inactive");
    }
}

export default Filter;