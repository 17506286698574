class Benefits {
  constructor() {
    this.init();
  }
  init() {
    [...document.querySelectorAll('.competencies_benefits.shortend')].map(e => this.loadMore(e));

    [...document.querySelectorAll('.competencies_benefits.with-animation')].map(e => this.animate(e));
  }

  loadMore(loadMoreBlock) {
    let id = loadMoreBlock.id;
      let loadMoreEl = document.getElementById(id)
      let texts = loadMoreEl.querySelectorAll('.content_box__item__description');
      let charLimit = 500;

      [...texts].forEach((text) => {

        if(text.innerHTML){
          if(text.innerHTML.length > charLimit){
            let showStr = text.innerHTML.slice(0,charLimit);
            let hideStr = text.innerHTML.slice(charLimit);
            if(text && !text.querySelector('.show-more') ){
              text.innerHTML = showStr + '<span class="show-more">...<span class="underlined">' + "weiterlesen" + '</span></span><span class="trimmed">' + hideStr + '</span>'
            }
          }
        }

      });
      const textTriggers = loadMoreEl.querySelectorAll('.content_box__item__description .show-more');
      [...textTriggers].forEach((textTrigger) => {
        textTrigger.addEventListener('click', function() {
          if(this.nextElementSibling){
            this.nextElementSibling.classList.remove('trimmed');
            this.remove();
          }
        });
      })
  }

  animate(block) {
    window.wp.utils.isElementInViewport(block, 
      () => {
        if(!block.classList.contains("ready")) {
          block.classList.add("animate");
          const children = block.querySelectorAll(".competencies_benefits__items > div"); 
          let time = 0;
          const chunkSize = 2;
          for (let i = 0; i < children.length; i += chunkSize) {
            const chunk = [...children].slice(i, i + chunkSize);
            chunk.forEach((child) => setTimeout(() => child.classList.add("animate"), time));
            time += 700;
          }
        }
      },
      () => {
        if(block.classList.contains("animate")) {
          const children = block.querySelectorAll(".competencies_benefits__items > div"); 
          children.forEach((child) => {
            child.classList.remove("animate");
            child.classList.add("ready");
          });
          block.classList.remove("animate");
          block.classList.add("ready");
        }
      },
      "-100px"
    );
  }
}

export default Benefits;
