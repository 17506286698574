export const lerp = function (value1, value2, amount) {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;
  return value1 + (value2 - value1) * amount;
};

export const toNode = (str) => {
  const d = document.createElement("div");
  d.innerHTML = str;
  return d.children[0];
};

export const isElementInViewport = (el, inD, outD, rootMargin) => {
  if (el !== null) {
    const margin = rootMargin || "-10%";
    function handleIntersect(entries, observer) {
      let entry = entries[0];
      if (entry.isIntersecting) {
        if (inD && typeof inD === "function") inD(el, entry);
      } else {
        if (outD && typeof outD === "function") outD(el, entry);
      }
    }
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: margin,
    });
    observer.observe(el);
  }
};

export const isInViewport = (elem) => {
  if (elem !== null) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }
};

export const getViewport = () => {
  let vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  let vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  return {
    vw,
    vh,
  };
};

export const shuffleArray = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
  return array;
}

export default class Utils {
  constructor() {
    this.lerp = lerp;
    this.toNode = toNode;
    this.isElementInViewport = isElementInViewport;
    this.isInViewport = isInViewport;
    this.getViewport = getViewport;
  }
}


// todo ... masking links
// // Function to mask email addresses using CSS selectors
// function maskEmailLinks() {
//   var emailLinks = document.querySelectorAll('a[href^="mailto:"]');
//   for (var i = 0; i < emailLinks.length; i++) {
//     var emailLink = emailLinks[i];
//     var email = emailLink.href.replace('mailto:', '');
//     emailLink.setAttribute('data-email', email);
//     emailLink.href = 'javascript:void(0)';
//     emailLink.addEventListener('click', openMailProgram);
//   }
// }
//
// // Function to open the default mail program
// function openMailProgram(event) {
//   event.preventDefault();
//   var email = event.currentTarget.getAttribute('data-email');
//   window.open('mailto:' + email);
// }
//
// // Call the function to mask email links
// maskEmailLinks();