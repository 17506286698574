class Hero {
  constructor() {
    this.backDown = undefined;
    this.backTop = undefined;
    this.init();
  }

  init() {
    this.backDown = document.querySelector("#back_to_down");
    this.backTop = document.querySelector("#back_to_top");
    this.wrapper = document.querySelector(".hero");

    if (this.backDown) {
      this.backDown.addEventListener("click", () => {
        this.downScroll();
      });
    }
    if (this.backTop) {
      this.backTop.addEventListener("click", () => {
        this.topScroll();
      });
    }
    if (this.wrapper){
      if (this.wrapper.classList.contains('light')){
        let closeIcon = document.querySelector(".close-icon");
        closeIcon.classList.add('dark');
      }
    }

  }

  topScroll() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  downScroll() {
    let { vh } = window.wp.utils.getViewport();
    document.body.scrollTop = vh;
    document.documentElement.scrollTop = vh;
  }
}

export default Hero;
