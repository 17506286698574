class WheelOfFortune {



    constructor() {
        this.container = undefined
        this.wheel = undefined
        this.init();
    }

    init() {
        this.container = document.querySelector(".wof-wheel-standalone");
        this.addEvents()
    }


    addEvents() {
        if(!this.container) {
            return;
        }
        const btnSubmit = this.container.querySelector("button[type='submit']");

        btnSubmit.addEventListener("click", () => {
            this.wheel = this.container.querySelector(".wof-left");
            setTimeout(()  => {
                if(!this.hasErrors())  {
                    this.scrollToWheel();
                }
            }, 1500)
        });
    }


    hasErrors() {
        const errorContainer  = this.container.querySelector('.wof-error');
        if(!errorContainer) {
            return false;
        }
        return errorContainer.style.display !== "none";
    }


    scrollToWheel() {
        if(!this.wheel) {
            return;
        }
        if (!this.isElementCompletelyVisible(this.wheel)) {
            this.wheel.scrollIntoView();
        }
    }


    isElementCompletelyVisible(el) {
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}

export default WheelOfFortune;